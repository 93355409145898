@use "breakpoints";
@use "colors";
@use "layout";
@use "links";
@use "typography";

.explore-clubs {
  block-size: var(--mobile-page-hero-block-size);
  display: grid;
  grid-template: 1fr / 1fr;
  overflow: hidden;
  user-select: none;

  @media (width >= breakpoints.$md) {
    aspect-ratio: 8 / 5;
    block-size: 100%;
  }
}

.content {
  @include layout.module-content;

  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  justify-content: center;
  z-index: 10;
}

.text {
  align-items: stretch;
  background-color: colors.$white;
  color: colors.$off-black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: auto 0 40px;
  max-inline-size: 271px;
  padding: 40px 24px;
  position: relative;
  z-index: 1;

  .title {
    @include typography.small-headline;

    color: colors.$black;
    margin: 0 0 24px;
  }

  .description {
    margin: 0 0 24px;
    white-space: pre-wrap;
  }

  .cta {
    @include links.regular-text-link-dark;
  }
}

.media {
  block-size: 100%;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  overflow: hidden;
}

@media (width < breakpoints.$md) {
  .explore-clubs[data-eqx-plus-app="true"] {
    aspect-ratio: auto;
    grid: 2fr / 1fr;

    .content {
      padding-inline: 0;
    }

    .text {
      flex: 1;
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
      margin: 0;
      max-inline-size: none;
    }

    .media {
      grid-row: 2 / span 1;
    }
  }
}

@media (width >= breakpoints.$md) {
  .text {
    inset-block-start: 0;
    justify-content: flex-start;
    margin-block: 40px auto;
    max-inline-size: 432px;
    padding: 64px 56px;

    .description {
      margin-block-end: 28px;
    }
  }
}

@media (width >= breakpoints.$xl) {
  .content {
    grid-template-columns: 1fr 1440px 1fr;
  }

  .text {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    margin-block-start: 124px;
  }

  .media {
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;
  }
}
